<template>
  <v-row>
    <v-col
    xs="5"
    class="text-left"
  >
    <v-btn
      x-small
      elevation="10"
      class="text-xs font-weight-semibold"
      color="primary darken-2"
      :to="{ name: 'message' }"
    >
      <font-awesome-icon
        icon="fa-solid fa-message"
        class="pe-1"
      />
      Send message
    </v-btn>
  </v-col>
  <v-col cols="12">
  <v-card>
    <v-card-title class="">
      <span class="me-3">Contact Us Messages</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="contactUsList"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.client_username`]="{item}">
        <router-link class="d-flex flex-column" :to="'/marketplace/contact_us/reply/'+item.id" style="cursor: pointer; text-decoration: none;">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.client_username }}</span>
          <small>Contact: {{ item.phone_number }}</small>
        </router-link>
      </template>
      <template #[`item.subject`]="{item}">
        {{ item.subject }}
      </template>

      <!-- status -->
      <template #[`item.read`]="{item}">
        <v-chip
          small
          v-if="item.read"
          color="primary"
          class="font-weight-medium"
        >
          Read
        </v-chip>
        <v-chip
          small
          v-else
          color="error"
          class="font-weight-medium"
        >
          UnRead
        </v-chip>
      </template>

      <!-- status -->
      <template #[`item.replied`]="{item}">
        <v-chip
          small
          v-if="item.read"
          color="success"
          class="font-weight-medium"
        >
          Replied
        </v-chip>
        <v-chip
          small
          v-else
          color="primary"
          class="font-weight-medium"
        >
          Unreplied
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
  </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {

    return {
      headers: [
        { text: 'Name', value: 'client_username' },
        { text: 'Subject', value: 'subject' },
        { text: 'Category', value: 'category' },
        { text: 'Status', value: 'read' },
        { text: 'Replied', value: 'replied' },
      ],
      contactUsList: [],
      status: {
        1: 'UnRead',
        2: 'Read',
        3: 'Unreplied',
        4: 'Replied',
      },
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      this.getContactUsList();
      document.title = "Manager: MarketPlace | Client's Contact Us"
  },

  methods:{
    getContactUsList(){
      axios
        .get('/api/v1/manager/Market Place/get/contact_us/')
        .then(response => {
          this.contactUsList = response.data  // get the data and fill into descriptions
          console.log(this.contactUsList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
  },
}
</script>
